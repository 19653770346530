import Layout from "@/components/Layout";
import { Seo } from "@/components/common";
import { PageProps } from "@/definitions";
import { graphql } from "gatsby";
import { default as React } from "react";

import imgUiux from "@/assets/1_design.jpg";
import imgMobile from "@/assets/2_mobile.jpg";
import imgCompanyProfile from "@/assets/3_company_profile.jpg";
import imgBackOffice from "@/assets/4_back_office.jpg";
import imgBackEnd from "@/assets/5_backend.jpg";
import ppImg from "../assets/pp.jpg";

const BusinessIndex: React.FC<PageProps> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const today = new Date();

  return (

    <Layout location={location} title={siteTitle}>
      <Seo title="Katili Jiwo Business" />

      <div className="relative min-h-[800px] mt-6 lg:mt-19">
        <h1 className="text-center mb-4 text-3xl font-extrabold text-gray-900 dark:text-white md:text-5xl lg:text-6xl">
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-skin-sky to-skin-emerald">
            I am available for
          </span>
          <span className="ml-3">
            collaboration
          </span>
        </h1>
        <h2 className="text-center text-md lg:text-xl text-gray-700 dark:text-white mt-6 ml-6 tracking-wide">
          Are you looking to build a new application? or need a talented person to collaborate with your project?
        </h2>
        <p className="text-center text-sm lg:text-xl text-gray-700 dark:text-white mt-2 ml-6 tracking-wide">
          I can help bring your vision to life with my expertise in some of this area
        </p>

        <br />
        <h2 className="text-4xl mt-12 tracking-wide mb-8 font-medium">My Services</h2>

        <div className="grid grid-cols-2 gap-6 md:grid-cols-3 mt-4">
          <div className="relative w-full h-40 md:h-60 rounded-lg">
            <div className="absolute transform transition duration-300 bg-gradient-to-t from-black/80 to-white-200/10 hover:bg-blue-900/20 w-full h-full z-10 rounded-2xl"></div>
            <img src={imgUiux} alt="" className="absolute object-cover w-full h-full z-0 rounded-2xl" />
            <h5 className="absolute bottom-0 left-0 ml-4 mb-3 text-sm md:text-lg lg:text-xl font-bold tracking-tight text-white z-20">
              UI/UX Web or Mobile Design
            </h5>
          </div>

          <div className="relative w-full h-40 md:h-60 rounded-2xl">
            <div className="transform transition duration-300 absolute bg-gradient-to-t from-black/80 to-white-200/10 hover:bg-blue-900/20 w-full h-full z-10 rounded-2xl"></div>
            <img src={imgMobile} alt="" className="absolute object-cover w-full h-full z-0 rounded-2xl" />
            <h5 className="absolute bottom-0 left-0 ml-4 mb-3 text-sm md:text-lg lg:text-xl font-bold tracking-tight text-white z-20">
              Mobile App
            </h5>
          </div>

          <div className="relative w-full h-40 md:h-60 rounded-2xl">
            <div className="transform transition duration-300 absolute bg-gradient-to-t from-black/80 to-white-200/10 hover:bg-blue-900/20 w-full h-full z-10 rounded-2xl"></div>
            <img src={imgCompanyProfile} alt="" className="absolute object-cover w-full h-full z-0 rounded-2xl" />
            <h5 className="absolute bottom-0 left-0 ml-4 mb-3 text-sm md:text-lg lg:text-xl font-bold tracking-tight text-white z-20">
              Company Profile Website
            </h5>
          </div>

          <div className="relative w-full h-40 md:h-60 rounded-2xl">
            <div className="transform transition duration-300 absolute bg-gradient-to-t from-black/80 to-white-200/10 hover:bg-blue-900/20 w-full h-full z-10 rounded-2xl"></div>
            <img src={imgBackOffice} alt="" className="absolute object-cover w-full h-full z-0 rounded-2xl" />
            <h5 className="absolute bottom-0 left-0 ml-4 mb-3 text-sm md:text-lg lg:text-xl font-bold tracking-tight text-white z-20">
              Back Office Website
            </h5>
          </div>

          <div className="relative w-full h-40 md:h-60 rounded-2xl">
            <div className="transform transition duration-300 absolute bg-gradient-to-t from-black/80 to-white-200/10 hover:bg-blue-900/20 w-full h-full z-10 rounded-2xl"></div>
            <img src={imgBackEnd} alt="" className="absolute object-cover w-full h-full z-0 rounded-2xl" />
            <h5 className="absolute bottom-0 left-0 ml-4 mb-3 text-sm md:text-lg lg:text-xl font-bold tracking-tight text-white z-20">
              Back End App
            </h5>
          </div>


        </div>

        <br />
        <hr className="my-5" />

        <h2 className="text-4xl font-medium pt-4 mt-10 mb-4">What I can offer</h2>
        <br />

        <h2 className="text-xl md:text-2xl">🚀 Handling from initial design to public launch</h2>
        <p className="text-xs sm:text-sm md:text-lg pt-1 text-gray-600 dark:text-gray-300">We're prepared to guide your project through all development stages, from designing concept, coding, testing, until successfully launching for public.</p>
        <br />

        <h2 className="text-xl md:text-2xl">🧑‍💻 Clean and Scalable Code</h2>
        <p className="text-xs sm:text-sm md:text-lg pt-1 text-gray-600 dark:text-gray-300">I prioritize clean code practices and leverage clean architecture principles to ensure the application is highly scalable and fosters long-term maintainability. This approach promotes code readability, modularity, and testability, allowing the application to adapt and grow seamlessly as requirements evolve.</p>
        <br />

        <h2 className="text-xl md:text-2xl">🤖 Use latest technology</h2>
        <p className="text-xs sm:text-sm md:text-lg pt-1 text-gray-600 dark:text-gray-300">By building with the latest technology, I'll ensure your project is adaptable and scalable, allowing it to grow and evolve alongside future advancements.</p>
        <br />

        <h2 className="text-xl md:text-2xl">🌐 SEO Friendly</h2>
        <p className="text-xs sm:text-sm md:text-lg pt-1 text-gray-600 dark:text-gray-300">I possess a comprehensive understanding of SEO best practices and the latest optimization tools. By leveraging this expertise, I can strategically implement tactics to enhance your website's discoverability. This will ensure your target audience finds you effortlessly through organic search engine results.</p>
        <br />

        <h2 className="text-xl md:text-2xl">🔒 Secure App</h2>
        <p className="text-xs sm:text-sm md:text-lg pt-1 text-gray-600 dark:text-gray-300">With my experience in professional career and learning from a lot of sources, I can deliver highly secure applications and websites. This ensures your project adheres to the latest security standards.</p>
        <br />
        <br />

        <hr />
        <br />

        <div className="mt-8">
          <div className="flex flex-wrap">
            <img className="w-10 h-10 rounded-full" src={ppImg} alt="" />
            <div className="ml-0 mt-3 sm:ml-6 sm:mt-0">
              <p className="text-2xl md:text-3xl font-medium">For a comprehensive overview, feel free to contact me on</p>
              <p className="text-sm sm:text-lg md:text-lg pt-1  mt-1">Phone (ID): <span className="font-semibold text-blue-500 dark:text-blue-200">(+62) 85157226470</span></p>
              <p className="text-sm sm:text-lg md:text-lg pt-1 ">Email: <span className="font-semibold text-blue-500 dark:text-blue-200">katili.jiwo.work@gmail.com</span></p>
            </div>
          </div>
        </div>

        <br />

        <div className="text-center my-12">
          <p className="text-xs sm:text-sm md:text-lg pt-1 font-medium">Let's foster a successful partnership that delivers exceptional results. </p>
          <p className="text-xs sm:text-sm md:text-lg pt-1 font-medium">Our goal is to build a long-lasting, mutually beneficial relationship where your success is our priority. 🥳</p>
        </div>
      </div>

      <footer className="flex flex-wrap justify-between w-full lg:text-left mt-20 border-t py-10 border-color-navborder">
        <div className="text-sm text-gray-700 dark:text-gray-400 px-6">
          <a className="text-blue-500 dark:text-blue-200 font-semibold" href="mailto:katili.jiwo.work@gmail.com">katili.jiwo.work@gmail.com</a> <br />
          <span className="font-normal">Jakarta, Indonesia</span>
        </div>
        <div className="text-sm font-medium float-right text-gray-700 dark:text-gray-400 px-6 mt-4 md:mt-0">
          © {today.getFullYear()} Katili Jiwo Adi W.
        </div>
      </footer>

    </Layout>
  );
}

export default BusinessIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`